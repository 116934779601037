import React from 'react'
import { graphql } from 'gatsby'
import AcademyLayout from 'container/AcademyLayout'
import FrontArticle from 'components/FrontArticle'
import ThumbDown from 'Assets/icons/ThumbDown.svg'
import ThumbUp from 'Assets/icons/ThumbUp.svg'
import BlogLayout from 'container/BlogLayout'
import ArticleCard from 'components/ArticleCard'
import HeroAction from 'components/HeroAction'
import EmailPicker from 'components/EmailPicker'
import BlogCard from 'components/BlogCard'

export default function Article({data}) {
  const hero = require('data/hero.json')
  const { currentArticle, sectionArticles } = data // data.markdownRemark holds your post data
  const { frontmatter } = currentArticle
  
  const {type} = frontmatter
  const inSection = []
  const impLinks = []

  sectionArticles.nodes.map(item=>{
    if(item.frontmatter.category === frontmatter.category && item.frontmatter.link !== 'index')
      inSection.push(<a href = {`/academy/${item.frontmatter.category}/${item.frontmatter.link}`}><div className='max-w-sm h-36 border rounded-md shadow-lg pt-4 pl-6 pr-2'>
      <h4 className='font-bold pb-1'> {item.frontmatter.title} </h4>
      <div className='text-sm'>{item.frontmatter.description}</div>
    </div></a>)

    else if(item.frontmatter.category !== frontmatter.category && item.frontmatter.link === 'index')
      impLinks.push(<a href = {`/academy/${item.frontmatter.category}`}><div className='max-w-sm h-36 border rounded-md shadow-lg pt-4 pl-6 pr-2'>
      <h4 className='font-bold pb-1'> {item.frontmatter.title} </h4>
      <div className='text-sm'>{item.frontmatter.description}</div>
    </div></a>)
  });

  return (
  <div>{ type==='academy' ? 
  <AcademyLayout category={frontmatter.category} link={frontmatter.link} articles={true}>
    <div class='max-w-7xl mx-auto'>
      <FrontArticle frontmatter={frontmatter}/>
      <div><div className='mt-6 mb-10'>
        <div>
          {inSection.length > 0 ? <div>
          <h3 className='font-bold text-lg mb-4'>In This Section</h3>
          <div className='grid grid-cols-3 gap-4'>
            {inSection}
          </div></div> : null}
        </div>
      </div>
      <div className='mt-6 mb-10'>
      <div>
          {impLinks.length > 0 ? <div>
          <h3 className='font-bold text-lg mb-4'>Important Links</h3>
          <div className='grid grid-cols-3 gap-4'>
            {impLinks}
          </div></div> : null}
        </div>
      </div>
      </div>
      <div className='mt-6 mb-10 font-bold text-lg flex items-center'>
        Was this page helpful? 
        <span>
          <button href='#' class='ml-8 bg-blue-700 hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center h-10'> 
            <img src={ThumbUp} alt='ThumbUp' class='h-4 mr-1'/> <span class='text-white text-sm'>Yes</span>
          </button>
        </span>
        <span>
          <button href='#' class='ml-4 bg-blue-700 hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded inline-flex items-center h-10'>
            <img src={ThumbDown} alt='ThumbDown' class='h-4 mr-1'/> <span class='text-white text-sm'>No</span>
          </button> 
        </span>
      </div>
    </div>
  </AcademyLayout> :
  <BlogLayout category={''}>
    <div className='max-w-7xl mx-auto sm:px-6 lg:px-8 md:justify-start mt-6'>
      <div className="flex space-x-6">
        <div className='grow max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-end justify-start'>
          <div className="lg:py-24 mr-6">
            <HeroAction hero={hero} />
            <div className="mt-10 sm:mt-12">
              <EmailPicker email={hero.email} />
            </div>
          </div>
        </div>
        {
          sectionArticles.nodes.map(node => {
            if(node.frontmatter.type===type && node.frontmatter.featured)
            return (
              <div className='flex-none w-64'>
                <BlogCard frontmatter={node.frontmatter}/>
              </div>
            )
          }).filter(node => node!=null && node).slice(0,2)
        }
      </div>
      <div className='grid grid-cols-1 md:grid-cols-2 justify-items-start py-10 gap-x-16 gap-y-20'>
        {sectionArticles.nodes.map(node => {
          if(node.frontmatter.type===type)
          return (
            <div>
              <ArticleCard frontmatter={node.frontmatter} blog={true}/>
            </div>
          )
        })}
      </div>
    </div>
  </BlogLayout>}
</div>
  )
}
export const pageQuery = graphql`
   query ($id: String!) {
    currentArticle: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        category
        link
        title
        heroImage
        description
        type
      }
      headings {
        id
        value
      }
    }
    sectionArticles : allMarkdownRemark {
      nodes {
        frontmatter {
          title
          description
          category
          link
          heroImage
          type
          tags
          featured
        }
      }
    }
  }
`
